import React from "react";
import Header from '../Header/Header'
import Sidebar from '../Header/Sidebar'

function SideAndHeadComponent(
    {
        children,
    }:{
        className?: string;
        children: React.ReactNode;
    }
) {
  return (
    <>
    <div className='w-full fixed z-10'>
    <Header />
    </div>
    <div className='flex flex-row gap-8'>
      <Sidebar />
      <div className="mt-32 ml-[264px] w-full">
      <section className='mx-8'>
        {children}
        </section>
      </div>
    </div>
    </>
  )
}

export default SideAndHeadComponent