import { BsArrowRightCircleFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import maskbg2 from "../../Assets/image/background/mask-bg-2.png"
import maskbg4 from "../../Assets/image/background/mask-bg-4.jpg"
import maskbg5 from "../../Assets/image/background/mask-bg-5.png"
import maskbg6 from "../../Assets/image/background/mask-bg-6.png"


export default function LicenceRegistrationComponent() {
  const dummy = [
    {
      name: "certificateOfImporters",
      image: maskbg4,
      link: "https://lm.doca.gov.in/OREGIS/",
    },
    {
      name: "directorOfCompaniesNominated",
      image: maskbg5,
      link: "https://doca.gov.in/cdn-info/",
    },
    {
      name: "modalApprovalSystem",
      image: maskbg6,
      link: "https://www.nsws.gov.in/",
    },
    {
      name: "packagedCommoditiesRegistration",
      image: maskbg2,
      link: "https://www.nsws.gov.in/",
    },
  ];

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <section className="bg-gradient-to-b from-white from-75% to-theme_bg to-75% section-padding">
      <div className="container">
        <h1 className="text-center mx-auto">
          {t('empower')} <span className="text-theme_yellow">{t('role')}</span>, {t('submitRequest')}
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 section-margin mx-auto sm:mx-8 md:mx-0 place-items-center">
          {dummy.map((item, index) => (
            <div className=" max-w-[311px] aspect-[0.7] w-full" key={index}>
              <div className="bg-gray-600 w-full h-full overflow-hidden relative">
                <img
                  className="object-center object-fill w-full h-full"
                  src={item.image}
                  alt={item.name}
                  width={311}
                  height={500}
                />
                <div className="absolute bottom-0 left-0 w-full bg-theme_yellow/85 flex flex-col gap-1 p-2 justify-between">
                  <h5 className="line-clamp-2 hover:line-clamp-none text-sm md:text-base font-semibold min-h-[6ch] md:min-h-0 lg:min-h-[6ch]">
                    {t(item.name)}
                  </h5>
                  <a href={item.link}>
                    <BsArrowRightCircleFill className=" size-[24px] md:size-[28px] lg:size-[32px]" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
