import UserInfo from './UserInfo'
import SideAndHeadComponent from '../Header/SideAndHeadComponent'

function UserDashboard() {
  return (
    <>
    <SideAndHeadComponent>
      < UserInfo />
    </SideAndHeadComponent>
    </>
  )
}

export default UserDashboard