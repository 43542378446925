
import {cn} from "../../../lib/utils";
import {ReactNode} from "react";
import { useLocation, Link } from "react-router-dom";

export default function ActiveLinkComponent({url, children}:{url:string,children:ReactNode}){
    const location = useLocation();
    const pathname = location.pathname;
        if(url=="/"){
        return (
            <Link
                to="/"
                className={cn(pathname == "/" ? "text-theme_yellow " : "", "hover:text-theme_yellow")}
            >
                {children}
            </Link>
        )
    }else{
        return (
            <Link to={url} className={cn(pathname.includes(url) ? "text-theme_yellow " : "", "hover:text-theme_yellow")}>{children}</Link>
        )
    }

}

export function SidebarLinkComponent({url, children}:{url:string,children:ReactNode}){
    const location = useLocation();
    const pathname = location.pathname;
        if(url=="/"){
        return (
            <Link
                to="/"
                className={cn(pathname == "/" ? "text-theme_yellow bg-white p-3 rounded-s-md " : "rounded-s-md p-3", "hover:text-theme_yellow")}
            >
                {children}
            </Link>
        )
    }else{
        return (
            <Link to={url} className={cn(pathname.includes(url) ? "text-theme_yellow bg-white p-3 rounded-s-md" : "rounded-s-md p-3", "hover:text-theme_yellow")}>{children}</Link>
        )
    }

}
