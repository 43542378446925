import Licence from "./Components/Dashboard/Licence/Licence";
import NewLicence from "./Components/Dashboard/Licence/NewLicence";
import RenewLicence from "./Components/Dashboard/Licence/RenewLicence";
import UpdateLicence from "./Components/Dashboard/Licence/UpdateLicence";
import NewRegistration from "./Components/Dashboard/Registration/NewRegistration";
import Registration from "./Components/Dashboard/Registration/Registration";
import UpdateRegistration from "./Components/Dashboard/Registration/UpdateRegistration";
import UserDashboard from "./Components/Dashboard/UserDashboard";
// import LicenceRegistrationComponent from "./Components/LicenseRegistrationComponent/LicenseRegistrationComponent";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import CreatePassword from "./Components/authentication/CreatePassword";
import EnterLoginOTP from "./Components/authentication/EnterLoginOTP";
import EnterRegisterOTP from "./Components/authentication/EnterRegisterOTP";
import Login from "./Components/authentication/Login";
import LoginWithOTP from "./Components/authentication/LoginWithOTP";
// import Otp from "./Components/authentication/Otp";
// import Password from "./Components/authentication/Password";
import Register from "./Components/authentication/Register";
// import LoadingScreen from "./Components/loadingscreen/loading.component";
import ActAndRulesPage from "./Pages/ActAndRulesPage";
import DashboardPage from "./Pages/DashboardPage";
import EnforcementPage from "./Pages/EnforcementPage";
import HomePage from "./Pages/HomePage";
import JudgementsPage from "./Pages/JudgementsPage";
import LicenceRegistrationPage from "./Pages/LicenseRegistrationPage";
import ReportsPage from "./Pages/ReportsPage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      {/* all routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/act_rules" element={<ActAndRulesPage />} />
        <Route path="/enforcements" element={<EnforcementPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/user_information" element={<LicenceRegistrationPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/otp" element={<Otp />} /> */}
        {/* <Route path="/password" element={<Password />} /> */}
        <Route path="/judgements" element={<JudgementsPage />} />
        <Route path="/login_with_otp" element={<LoginWithOTP />} />
        <Route path="/enter_login_otp" element={<EnterLoginOTP />} />
        <Route path="/enter_register_otp" element={<EnterRegisterOTP />} />
        <Route path="/create_password" element={<CreatePassword />} />
        <Route path="/user_dashboard" element={<UserDashboard />} />
        {/* Here are the routes of user Licence for new licence, update licence and renew licence */}
        <Route path="/licence" element={<Licence />} />
        <Route path="/licence/new_licence" element={<NewLicence />} />
        <Route path="/licence/update_licence" element={<UpdateLicence />} />
        <Route path="/licence/renew_licence" element={<RenewLicence />} />
        {/* Here are the routes of user Registration for new Registration, update Registration */}
        <Route path="/registration" element={<Registration />} />
        <Route
          path="/registration/new_registration"
          element={<NewRegistration />}
        />
        <Route
          path="/registration/update_registration"
          element={<UpdateRegistration />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
