import logo from "../../Assets/image/footer/NIC.png";
import f2 from "../../Assets/image/footer/e-jagritiLogo.png";
import fi1 from "../../Assets/image/footer/fi1.png";
import fi2 from "../../Assets/image/footer/fi2.png";
import fi3 from "../../Assets/image/footer/fi3.png";
import fi4 from "../../Assets/image/footer/fi4.png";
import fi6 from "../../Assets/image/footer/fi6.png";
import fi7 from "../../Assets/image/footer/fi7.png";
import emblem from "../../Assets/image/footer/new/ashoka.svg";
import bis from "../../Assets/image/footer/new/bis.svg";
import jgj from "../../Assets/image/footer/new/jgj.svg";
import nccf from "../../Assets/image/footer/new/nccf.svg";
import nth from "../../Assets/image/footer/new/nth.svg";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import AutoScroll from "embla-carousel-auto-scroll";
import React from "react";
import {
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import { Link } from "react-router-dom";

const NewFooter = () => {
  const buildDate = window.BUILD_DATE;
  const plugin = React.useRef(
    AutoScroll({ speed: 1, stopOnInteraction: true, stopOnMouseEnter: true })
  );
  const footerLink = [
    { lable: "Enforcement Activities", link: "/enforcements" },
    { lable: "Judgements", link: "/judgements" },
    { lable: "Legal Metrology Acts", link: "/act_rules" },
    { lable: "Reports", link: "/reports" },
    // { lable: "Feedback", link: "/feedback" },
    // { lable: "Disclaimer", link: "/disclaimer" },
    // { lable: "Help", link: "/help" }
  ];

  const footerImageUrl = [
    {
      link: "https://www.bis.gov.in/",
      image: bis,
    },
    {
      link: "https://www.nth.gov.in/",
      image: nth,
    },
    {
      link: "https://jagograhakjago.gov.in/",
      image: jgj,
    },
    {
      link: "https://jagograhakjago.gov.in/cwf/Default.aspx",
      image: emblem,
    },
    {
      link: "https://nccf-india.com/",
      image: nccf,
    },

    {
      link: "https://jagograhakjago.gov.in/cwf/Default.aspx",
      image: fi1,
    },
    {
      link: "https://consumerhelpline.gov.in/public/",
      image: fi2,
    },
    {
      link: "#",
      image: fi3,
    },
    {
      link: "https://www.bis.gov.in/",
      image: fi4,
    },
    {
      link: "https://e-jagriti.gov.in/",
      image: f2,
    },
    {
      link: "https://nccf-india.com/",
      image: fi6,
    },
    {
      link: "https://www.digitalindia.gov.in/",
      image: fi7,
    },
  ];

  return (
    <div className="mt-16">
      <Carousel
        plugins={[plugin.current]}
        onMouseLeave={() => {
          plugin.current.play();
        }}
        opts={{
          align: "start",
          loop: true,
        }}
        className="bg-[#f2f2f2]"
      >
        <CarouselContent className=" flex items-center gap-8 lg:gap-20 xl:gap-28 py-4 px-8">
          {" "}
          {footerImageUrl.map((item, i) => (
            <CarouselItem key={i} className="flex-none">
              <a
                className="flex flex-row items-center gap-2 lg:gap-4 h-[2.5rem] lg:h-[3.875rem]"
                href={item.link}
                target="_blank"
              >
                <img
                  src={item.image}
                  alt={"" + i + "logo"}
                  className="aspect-auto h-[2rem] lg:h-[2.5rem]"
                />
                {/* <h6 className="text-xs md:text-sm lg:text-base text-white max-w-[20ch]">
                  {item.name}
                </h6> */}
              </a>
            </CarouselItem>
          ))}
          {footerImageUrl.map((item, i) => (
            <CarouselItem key={i} className="flex-none">
              <a
                className="flex flex-row items-center gap-2 lg:gap-4 h-[2.5rem] lg:h-[3.875rem]"
                href={item.link}
                target="_blank"
              >
                <img
                  src={item.image}
                  alt={"" + i + "logo"}
                  className="aspect-auto h-[2rem] lg:h-[2.5rem]"
                />
                {/* <h6 className="text-xs md:text-sm lg:text-base text-white max-w-[20ch]">
                  {item.name}
                </h6> */}
              </a>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>

      <div className="bg-[#006699] text-white">
        <div className="flex justify-center gap-8 flex-wrap py-4">
          <a href="https://www.nic.in/">
            <img height="30" src={logo} alt="NIC" className="h-[35px] mb-2" />
          </a>
        </div>

        {/* Links Section */}
        <div className="flex flex-wrap justify-center gap-4 mb-4">
          {footerLink.map((item) => (
            <Link
              key={item.lable}
              to={item.link}
              className="text-white no-underline hover:underline"
            >
              {item.lable}
            </Link>
          ))}
        </div>

        {/* Social Media Icons */}
        <div className="text-center my-4 flex justify-center space-x-3">
          <a href="https://twitter.com/consaff">
            <IoIosGlobe className="mx-2 text-white" size={27} />
          </a>
          <a href="https://www.facebook.com/ConsumerAdvocacy/">
            <FaFacebook className="mx-2 text-white" size={25} />
          </a>
          <a href="https://www.instagram.com/consumeraffairs_goi/?igsh=MXg0d3M2aDN5NTQ0NA%3D%3D">
            <FaLinkedin className="mx-2 text-white" size={25} />
          </a>
          {/* <a href="https://www.youtube.com/channel/UCTyQpXH6_4xVR1CUyuPkm0Q">
            <FaYoutube className="mx-2 text-white"  size={25}/>
          </a> */}
        </div>

        {/* Copyright Section */}
        <div className="text-center text-sm border-t border-white/50 py-4">
          <div className="flex justify-around flex-wrap">
            <p className="text-[13px] text-white">
              Copyright © 2024 emaap. All rights reserved | Site designed,
              developed, and maintained by
              <a
                href="https://www.nic.in/"
                className="text-white ml-1 no-underline"
              >
                National Informatics Centre, Ministry of Electronics & IT, Govt.
                of India.
              </a>
            </p>
            <p className="text-[13px] text-white">Last Updated: {buildDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
