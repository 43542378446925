import ReportMainComponent from '../Components/ReportsComponent/ReportMainComponent';
import Navbar from '../Components/Navbar/Navbar';
import NewFooter from '../Components/Footer/NewFooter';

const ReportsPage = () => {
  return (
    <div>
        <Navbar/>
        <ReportMainComponent/>
        <NewFooter/>
    </div>
  )
}

export default ReportsPage