import NewFooter from "../Components/Footer/NewFooter";
// import Footer from '../Components/Footer/Footer'
import JugementsComponent from "../Components/Judgements/JugementsComponent";
import Navbar from "../Components/Navbar/Navbar";
import React from "react";

function JudgementsPage() {
  return (
    <>
      <Navbar />
      <JugementsComponent />
      <NewFooter />
    </>
  );
}

export default JudgementsPage;
