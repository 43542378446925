import SideAndHeadComponent from '../../Header/SideAndHeadComponent'
import NavigateButton from '../../../Components/Dashboard/Buttons/NavigateButton';


    


function Registration() {

    const tabs = [
        { label: "NEW/FRESH APPLICATION", url:"/registration/new_registration" },
        { label: "UPDATE CERTIFICATE", url:"/registration/update_registration" },
    ];

  return (
    <SideAndHeadComponent>
        
        <div>
          <h4>Registration</h4>
          <p className='font-semibold my-4'>Apply As<span className='text-[red]'>*</span></p>
          <NavigateButton tabs={tabs}/>
        </div>
      
    </SideAndHeadComponent>
  )
}

export default Registration
