import { useState } from 'react'
import { Tabs } from "../../../Components/ui/tabs";
import { Link } from 'react-router-dom';

type Tabs={
    label:string,
    url:string
}

type ButtonProps={
    tabs:Tabs[]
}

const NavigateButton = ({tabs}:ButtonProps) => {
    const [activeTab, setActiveTab] = useState<number>(0); 

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {tabs.map((item,index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`px-4 py-2 rounded-3xl transition-colors duration-300 border-2 text-sm border-black ${activeTab === index ? "bg-black text-white" : "text-black"
                            }`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <Link to={tabs[activeTab].url}>
            <button className='my-6 px-12 py-3 rounded-lg bg-theme_yellow font-bold text-sm'>NEXT</button>
            </Link>
            

        </>
    )
}

export default NavigateButton