import banner1 from "../../Assets/Banners/Banner1b.jpg";
import banner2 from "../../Assets/Banners/Banner2b.jpg";
import banner3 from "../../Assets/Banners/Banner3b.jpg";
import LicenceSearchComponent from "./LicenceSearchComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Data Configuration
const banners = [banner1, banner2, banner3];
const tags = ["The Bridge", "Easy Tracking", "Easy Approvals"];
const headings = [
  "eMaap: The Bridge between your Business and your Legal Requirements",
  "Verify your Weight & Measures and Maintain Easy Tracking Record Through Your Personal Dashboard",
  "Get Easy Approvals of Your Certificates and Licences",
];

function LandingComponent2() {
  const { t, i18n } = useTranslation("home");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        setIsFading(false);
      }, 400); // Animation duration matches CSS
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="landing_section min-h-[365px]">
      <div className="relative">
        {/* Banner with Fade Animation */}
        <img
          src={banners[currentIndex]}
          className={`min-h-[315px] w-full object-cover transition-opacity duration-1000 blur-sm md:blur-none ${
            isFading ? "opacity-0" : "opacity-100"
          }`}
          alt="Banner"
        />
        {/* Overlay Content */}
        <div className="absolute bottom-10 md:bottom-28 right-0 lg:bottom-1/3 px-4 md:px-16 lg:px-16 w-full lg:w-1/2">
          <div className="tracking-wide flex flex-col gap-3">
            {/* Tag */}
            <h4>
              <span className="text-white text-sm px-3 py-1 bg-black bg-opacity-60 rounded-md">
                {tags[currentIndex]}
              </span>
            </h4>
            {/* Heading */}
            <h2 className="text-white font-semibold">
              {headings[currentIndex].split(" ").map((word, index) =>
                word === "eMaap:" ? (
                  <span key={index} className="text-black">
                    {word}{" "}
                  </span>
                ) : (
                  <span key={index}>{word} </span>
                )
              )}
            </h2>
            {/* Search Component */}
            <div className="p-2 bg-black bg-opacity-50  max-w-[780px]">
              <p className="text-white text-sm font-semibold">
                {t("Know Your Certificate/Licence Status")}
              </p>
              <LicenceSearchComponent t={t} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingComponent2;
