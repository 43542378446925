import { InputForm } from "../Helper/form/input.form";
import { Button } from "../ui/button";
import { LoginComponent } from "./login.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CreatePassword() {
  const Navigate = useNavigate();

  const [passwordview, setPasswordview] = useState(false);

  const handleSubmit = () => {
    Navigate("/dashboard");
  };

  return (
    <>
      <LoginComponent>
        <form className="flex flex-col gap-3 mt-4" onSubmit={handleSubmit}>
          <InputForm
            eyebutton={true}
            placeholder={"Enter your password"}
            readonly={false}
            dataBlur={(val) => {}}
            dataChange={(val) => {}}
            name="Password"
            showpassword={(val) => {
              setPasswordview(val);
            }}
            passvisble={passwordview}
            type={passwordview ? "Password" : "text"}
            label="Create Password"
            labelcolor=""
            //   min={100}
            //   max={100}
          />

          <InputForm
            eyebutton={true}
            placeholder={"Confirm password"}
            readonly={false}
            dataBlur={(val) => {}}
            dataChange={(val) => {}}
            name="Password"
            showpassword={(val) => {
              setPasswordview(val);
            }}
            passvisble={passwordview}
            type={passwordview ? "Password" : "text"}
            label="Confirm Password"
            labelcolor=""
            //   min={100}
            //   max={100}
          />

          <p className="text-xs text-gray-500 tracking-tight">
            Password must have minimum 8 character long as well as contains
            at-least one capital letter and one special character
          </p>

          <Button
            className="rounded-md uppercase border-none font-bold w-full h-12"
            size="long"
            variant="default"
          >
            submit
          </Button>
        </form>
      </LoginComponent>
    </>
  );
}

export default CreatePassword;
