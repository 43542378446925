
import { Skeleton } from "../../../Components/ui/skeleton";

// skelton body for reports Table...............................................................................................................
export const renderSkeletonTable = () => (
    <div className="p-4 animate-pulse">
      {/* Skeleton for the table header */}
      <div className="grid grid-cols-5 gap-2 mb-4 ">
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} className="h-8 w-full bg-gray-300 rounded-md" />
        ))}
      </div>
      {/* Skeleton for table rows */}
      {[...Array(5)].map((_, rowIndex) => (
        <div
          key={rowIndex}
          className="grid grid-cols-5 gap-2 mb-2 animate-pulse"
        >
          {[...Array(5)].map((_, colIndex) => (
            <Skeleton
              key={colIndex}
              className="h-6 w-full bg-gray-200 rounded-sm"
            />
          ))}
        </div>
      ))}
    </div>
  );