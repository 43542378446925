import React from "react";
import NewFooter from "../Components/Footer/NewFooter";
import Navbar from "../Components/Navbar/Navbar";
// import LandingComponent from "../Components/HomePageComponent/LandingComponent";
import ServiceComponent from "../Components/HomePageComponent/ServiceComponent";
import OverviewComponent from "../Components/HomePageComponent/OverviewComponent";
import ClmsWorksComponent from "../Components/HomePageComponent/ClmsWorkComponent";
import StatesComponent from "../Components/HomePageComponent/StatesComponent";
import LicenceRegistrationComponent from "../Components/HomePageComponent/LicencesRegistrationComponent";
// import SelectSectorComponent from "../Components/HomePageComponent/SelectSecotorComponent";
import InsightsComponent from "../Components/HomePageComponent/InsightComponent";
import StayUpdatedComponent from "../Components/HomePageComponent/StayUpdatedComponent";
import LandingComponent2 from "../Components/HomePageComponent/LandingComponent2";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <LandingComponent2 />
      <ServiceComponent />
      <OverviewComponent />
      <ClmsWorksComponent />
      <StatesComponent />
      <LicenceRegistrationComponent />
      {/* <SelectSectorComponent /> */}
      <InsightsComponent />
      <StayUpdatedComponent />
      <NewFooter />
    </>
  );
};

export default HomePage;
