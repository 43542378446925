
import SideAndHeadComponent from '../../Header/SideAndHeadComponent'

function NewLicence() {
  return (
    <SideAndHeadComponent>
      New Registration banayege yhan hum.....
    </SideAndHeadComponent>
  )
}

export default NewLicence
