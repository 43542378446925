import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { GetLicenceData } from "./licence.service";
import { TFunction } from "i18next";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";

const LicenceSearchComponent = ({ t }: { t: TFunction }) => {
  const [licenceNumber, setLicenceNumber] = useState<string>("");
  const [certificateType, setcertificateType] = useState<string>("");

  const fetchLicenceData = async (e: any) => {
    if (!certificateType || certificateType.length === 0) {
      Swal.fire({
        title: "Please select certificate type!",
        icon: "warning",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
      return;
    }

    if (!licenceNumber) {
      Swal.fire({
        title: "Please fill certificate no!",
        icon: "warning",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
      return;
    }

    const licence = await GetLicenceData(licenceNumber, certificateType);
    setLicenceNumber("");

    if (!licence) {
      Swal.fire({
        title: "Something went wrong!",
        icon: "warning",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
      return;
    }

    try {
      const pdfUrl = `${process.env.REACT_APP_FILE_URL}${licence.link}`;
      const response = await fetch(pdfUrl);

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();

      const pdfBlob = new Blob([blob], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(pdfBlob);

      const newTab = window.open();
      if (newTab) {
        newTab.location.href = blobUrl;
      } else {
        throw new Error(
          "Failed to open a new tab. Please allow pop-ups in your browser."
        );
      }

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      Swal.fire({
        title: "Failed to open the PDF!",
        text: `error.message`,
        icon: "error",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
  };

  return (
    <div className="flex flex-row gap-2 max-w-[780px] group p-2 border rounded-sm">
      <Select
        onValueChange={(value) => {
          setcertificateType(value);
        }}
      >
        <SelectTrigger className=" bg-white max-w-[170px] w-auto rounded-none px-1 md:px-2 lg-px-2 xl:px-4">
          <SelectValue placeholder="Certificate Type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="AS">Modal Approval System</SelectItem>
          <SelectItem value="WM">Certificates of importers</SelectItem>
          <SelectItem value="CR">Packaged Commodities Registration</SelectItem>
          <SelectItem value="CN">
            Directors of the Companies Nominated
          </SelectItem>
        </SelectContent>
      </Select>
      <Input
        value={licenceNumber}
        onChange={(e) => setLicenceNumber(e.target.value)}
        className="w-32 px-1 rounded-none flex-grow focus-visible:ring-0 group-focus-visible:outline  focus-visible:ring-offset-0"
        placeholder={t("certificatePlaceholder")}
      />
      <Button
        className="hidden lg:block rounded-none bg-theme_yellow text-black flex-none px-[1px] md:px-2 lg:px-2 xl:px-4"
        onClick={(e) => {
          fetchLicenceData(e);
        }}
      >
        SUBMIT
      </Button>
      <Button className="rounded-none bg-theme_yellow text-black flex-none px-2 md:px-2 lg:hidden">
        <FiSearch />
      </Button>
    </div>
  );
};

export default LicenceSearchComponent;
