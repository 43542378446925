import { InputForm } from "../Helper/form/input.form";
import { Button } from "../ui/button";
import { LoginComponent } from "./login.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function LoginWithOTP() {
  const Navigate = useNavigate();
  const [loginMobileNo, setloginMobileNo] = useState("");

  // const handleNavigate = () => {
  //   Navigate("/enter_login_otp");
  // };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (loginMobileNo === "123456789") {
      Navigate("/enter_login_otp");
    } else {
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
  };
  return (
    <>
      <LoginComponent>
        <form className=" flex flex-col gap-3 mt-16" onSubmit={handleSubmit}>
          <p className="tracking-tight text-sm mb-3 text-center">
            Enter your registered mobile number or email
          </p>

          <InputForm
            placeholder={"Enter your email/mobile number"}
            readonly={false}
            dataBlur={(val) => {}}
            dataChange={(val) => setloginMobileNo(val)}
            name="Email or Mobile"
            type="string"
            label="Email/Mobile"
            labelcolor=""
            isRequired={true}
            //   min={100}
            //   max={100}
          />

          <Button
            className="mt-5 rounded-md uppercase border-none font-bold w-full h-12"
            size="long"
            variant="default"
          
          >
            Next
          </Button>
        </form>
      </LoginComponent>
    </>
  );
}

export default LoginWithOTP;
