import { SidebarLinkComponent } from '../Helper/activeLink/activeLink.component'
import { SiWindows } from "react-icons/si";
import { BiSolidWallet } from "react-icons/bi";
import { TiDocumentAdd } from "react-icons/ti";
import { PiCertificateFill } from "react-icons/pi";
import { HiDocumentArrowDown } from "react-icons/hi2";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { MdModelTraining } from "react-icons/md";


function Sidebar() {
  return (
    <div className='fixed flex-col items-start bg-[#222429] w-[264px] h-screen pl-6 text-white text-sm tracking-wide'>
      <div className='flex flex-col mt-28 gap-1 font-light'>
      <SidebarLinkComponent  url='/user_dashboard'>
      <div className='flex items-center gap-2'><SiWindows /> Dashboard</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/licence'>
      <div className='flex items-center gap-2'><PiCertificateFill size={20} /> Licence</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/registration'>
      <div className='flex items-center gap-2'><TiDocumentAdd size={22} /> Registration</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='19'>
      <div className='flex gap-2'><HiOutlineClipboardDocumentCheck size={25} /> Verification and Re-Verification</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/12'>
      <div className='flex gap-2'><HiDocumentArrowDown size={35} /> Certificate of importers of weight and measures</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/13'>
      <div className='flex items-center gap-2'><MdModelTraining size={25} /> Modal Approval system</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/14'>
      <div className='flex gap-1'><TiDocumentAdd size={30} /> Nomination of Director of companies</div>
      </SidebarLinkComponent>
      <SidebarLinkComponent url='/15'>
      <div className='flex items-center gap-2'><BiSolidWallet size={20} /> Payment</div>
      </SidebarLinkComponent>
      
      </div>
     
    </div>
  )
}

export default Sidebar
