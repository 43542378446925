"use client";
import { InputForm } from "../Helper/form/input.form";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { FaRegTrashAlt } from "react-icons/fa";
import { CgAddR } from "react-icons/cg";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import DropdownSelect2SingleSelect, {
  CreateOptionMultiSelectOptionOnlyLabel,
} from "../Helper/form/select2/select.dropdown";
import SingleDatePicker from "../Helper/form/datepicker/single/single.datepicker";
export default function NewLicensePage() {
  const StatesData = ["Maharashtra", "Delhi", "Noida", "Kanpur"];
  const DistrictData = ["District1", "District2"];
  const PostOfficeData = ["Post Office 1", "Post Office 2"];

  return (
    <section className=" tracking-wide">
      <h4>New Licence</h4>
      <Tabs defaultValue="communication_details" className="mt-4 ">
        <div className="text-center text-sm">
          <TabsList className="bg-[#EFF0F4] rounded-full">
            <TabsTrigger value="communication_details">
              COMMUNICATION DETAILS
            </TabsTrigger>
            <TabsTrigger value="company-tax-information">
              COMPANY & TAX INFORMATION
            </TabsTrigger>
            <TabsTrigger value="upload-document">UPLOAD DOCUMENTS</TabsTrigger>
            <TabsTrigger value="payment">PAYMENT</TabsTrigger>
          </TabsList>
        </div>
        
        <TabsContent value="communication_details">
          <Card className="border-none shadow-none text-sm">
            <CardContent className=" ">
              <p className="my-4 font-medium text-[#8F8F8F] text-sm">DETAILS OF ESTABLISMENTS/SHOP/PERSON SEEKING THE LICENCE</p>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="Title"
                  type="text"
                  dataChange={() => {}}
                  name="nameofestab"
                  isRequired={true}
                  placeholder="Mr"
                ></InputForm>
                <InputForm
                  label="Full Name"
                  type="text"
                  dataChange={() => {}}
                  name="full-name"
                  isRequired={true}
                  placeholder="Enter your full name"
                ></InputForm>
                <InputForm
                  label="Establishment/Shop Title"
                  type="text"
                  dataChange={() => {}}
                  name="shop-title"
                  isRequired={false}
                  placeholder="M/S"
                ></InputForm>
                <InputForm
                  label="Establishment/Shop Name"
                  type="text"
                  dataChange={() => {}}
                  name="shop-name"
                  isRequired={true}
                  placeholder="Enter name here"
                ></InputForm>
              </div>

              <p className="my-4 font-medium text-[#8F8F8F] text-sm">ADDRESS OF ESTABLISHMENT</p>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="House No./Door No./Building/Flat"
                  type="text"
                  dataChange={() => {}}
                  name="houseno"
                  isRequired={true}
                  placeholder="For e.g. A-34, Building Name"
                ></InputForm>
                <InputForm
                  label="Block/ Street/ Mohalla/ Sector"
                  type="text"
                  dataChange={() => {}}
                  name="blockstreet"
                  isRequired={true}
                  placeholder="For e.g. Sector 32"
                ></InputForm>
                <InputForm
                  label="Area/Suburb"
                  type="text"
                  dataChange={() => {}}
                  name="area"
                  // isRequired={true}
                  placeholder="For e.g. Chandigarh"
                ></InputForm>
                <InputForm
                  label="Landmark/Locality"
                  type="text"
                  dataChange={() => {}}
                  name="landmark"
                  // isRequired={true}
                  placeholder="For e.g. Near railway station"
                ></InputForm>
                <InputForm
                  label="PIN Code"
                  type="number"
                  dataChange={() => {}}
                  name="pincode"
                  isRequired={true}
                  placeholder="Enter your area pincode"
                ></InputForm>

                <DropdownSelect2SingleSelect
                  name={"state"}
                  label={"State"}
                  isRequired={true}
                  //  selectedData={states}
                  dataOptions={StatesData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />
                <DropdownSelect2SingleSelect
                  name={"district"}
                  label={"District"}
                  isRequired={true}
                  dataOptions={DistrictData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />

                <DropdownSelect2SingleSelect
                  name={"postoffice"}
                  label={"Post Office"}
                  isRequired={true}
                  dataOptions={PostOfficeData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="company-tax-information">
          <Card className=" border-none shadow-none text-sm">
            <CardContent className="mt-10">
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <DropdownSelect2SingleSelect
                  name={"businesstype"}
                  label={"Business Type"}
                  isRequired={true}
                  dataOptions={StatesData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />
                <InputForm
                  label="Registration Number"
                  dataChange={(value) => {}}
                  name="regnumber"
                  type="text"
                  isRequired={true}
                  placeholder="Reg. no. of current trade license"
                ></InputForm>
                <SingleDatePicker
                  name={"registrationdate"}
                  label={"Registration Date"}
                  isRequired={true}
                  dataChange={() => {}}
                />
                <SingleDatePicker
                  name={"dateofestabilishment"}
                  label={"Date of Estabilishment"}
                  isRequired={true}
                  dataChange={() => {}}
                />
              </div>

              <h5 className="text-paragraph my-4">
                NAME(S) AND ADDRESS(S) OF PROPRIETOR(S) AND/OR PARTNER(S) AND
                MANAGING DIRECTOR(S)
              </h5>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="Name"
                  name="name"
                  type="text"
                  dataChange={() => {}}
                  isRequired
                  placeholder="Enter name"
                ></InputForm>
                <InputForm
                  label="Father/Husband Name"
                  name="fathername"
                  type="text"
                  isRequired
                  dataChange={() => {}}
                  placeholder="Enter father/husband name"
                ></InputForm>

                <DropdownSelect2SingleSelect
                  name={"type"}
                  label={"Designation"}
                  isRequired={true}
                  dataOptions={[
                    {
                      label: "Designation",
                      value: "designation",
                    },
                  ]}
                  onDataChange={(value) => {}}
                />

                <InputForm
                  label="House No./Building/Flat"
                  type="text"
                  name="house_no"
                  dataChange={() => {}}
                  isRequired
                  placeholder="For e.g. A-34, Building name"
                ></InputForm>
                <InputForm
                  label="Block/Street/Maholla/Sector"
                  name="sector"
                  dataChange={() => {}}
                  type="text"
                  isRequired
                  placeholder="For e.g. Sector 32"
                ></InputForm>
                <InputForm
                  label="Area/Suburb"
                  name="area"
                  isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="For e.g. Chandigarh"
                ></InputForm>
                <InputForm
                  label="Landmark/Locality"
                  name="landmark"
                  isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter your name"
                ></InputForm>
                <InputForm
                  label="PIN Code"
                  name="pin_code"
                  dataChange={() => {}}
                  type="number"
                  isRequired={true}
                  placeholder="Enter your name"
                ></InputForm>
                <DropdownSelect2SingleSelect
                  name={"state"}
                  label={"State"}
                  isRequired={true}
                  //  selectedData={states}
                  dataOptions={StatesData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />
                <DropdownSelect2SingleSelect
                  name={"district"}
                  label={"District"}
                  isRequired={true}
                  //  selectedData={states}
                  dataOptions={StatesData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />

                <DropdownSelect2SingleSelect
                  name={"postoffice"}
                  label={"Post Office"}
                  isRequired={true}
                  //  selectedData={states}
                  dataOptions={StatesData.map((x) =>
                    CreateOptionMultiSelectOptionOnlyLabel(x)
                  )}
                  onDataChange={() => {}}
                />
              </div>
              <div className="mt-2 flex justify-end items-center">
                <button className="bg-black_bg text-theme_yellow flex justify-center items-center gap-1 w-1/12 rounded-lg p-2">
                  <CgAddR className="font-bold bg-amber-500 text-black" />
                  <span className="font-bold font-mono">ADD</span>
                </button>
              </div>
              <div>
                <Table className="mt-4 ">
                  {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
                  <TableHeader className="bg-black_bg text-white">
                    <TableRow>
                      <TableHead className="w-[100px]">S.No</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Father/Husband Name</TableHead>
                      <TableHead>Designation</TableHead>
                      <TableHead>Address</TableHead>
                      <TableHead className="flex justify-center items-center">
                        Action
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow className="text-paragraph">
                      <TableCell>1</TableCell>
                      <TableCell>Akhil Gupta</TableCell>
                      <TableCell>Manish Gupta</TableCell>
                      <TableCell>Director</TableCell>
                      <TableCell>House no. 32, Belur, Howrah</TableCell>
                      <TableCell className="text-red-700 flex justify-center">
                        <FaRegTrashAlt />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <h5 className="text-paragraph my-4">
                CATEGORIES OF WEIGHTS AND MEASURES SOLD OR PROPOSED TO BE SOLD
                AT PRESENT
              </h5>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2">
                <DropdownSelect2SingleSelect
                  name={"selectcategory"}
                  label={"Select Category"}
                  isRequired={true}
                  dataOptions={[
                    {
                      label: "Category",
                      value: "category",
                    },
                  ]}
                  onDataChange={(value) => {}}
                />

                <InputForm
                  label="Capacity"
                  name="capacity"
                  isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter capacity"
                ></InputForm>
              </div>
              <div className=" mt-2 flex justify-end items-center">
                <button className="bg-black_bg text-theme_yellow flex justify-center items-center gap-1 w-1/12 rounded-lg p-2">
                  <CgAddR className="font-bold bg-amber-500 text-black" />
                  <span className="font-bold font-mono">ADD</span>
                </button>
              </div>
              <div>
                <Table className="mt-4 ">
                  {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
                  <TableHeader className="bg-black_bg text-white">
                    <TableRow>
                      <TableHead className="w-[100px]">S.No</TableHead>
                      <TableHead>Category</TableHead>
                      <TableHead>Capacity</TableHead>
                      <TableHead className="flex justify-center items-center">
                        Action
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow className="text-paragraph">
                      <TableCell>1</TableCell>
                      <TableCell>
                        Automatic rail weight bridges class 0.2
                      </TableCell>
                      <TableCell>200 KG</TableCell>
                      <TableCell className="text-red-800 flex justify-center">
                        <FaRegTrashAlt />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <h5 className="text-paragraph my-4">TAX INFORMATION</h5>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="Registration Number of Sales Tax"
                  name="registration number"
                  dataChange={() => {}}
                  type="text"
                  placeholder="Reg. no. of sales tax"
                ></InputForm>
                <InputForm
                  label="Registration Number of Professional Tax"
                  name="capacity"
                  dataChange={() => {}}
                  type="text"
                  placeholder="Reg. no. of professional tax"
                ></InputForm>
                <InputForm
                  label="Registration Number of Income Tax"
                  name="capacity"
                  isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Reg. no. of income tax"
                ></InputForm>
                <InputForm
                  label="GST Number"
                  name="gstnumber"
                  isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter GST number"
                ></InputForm>
              </div>
              <h5 className="text-paragraph my-4">IMPORT INFORMATION</h5>
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 ">
                <DropdownSelect2SingleSelect
                  name={"selectcategory"}
                  label={
                    "Do You Intent to Import Weights, Measures etc from Places outside The State?"
                  }
                  dataOptions={[
                    {
                      label: "Category",
                      value: "category",
                    },
                  ]}
                  onDataChange={(value) => {}}
                />
                <DropdownSelect2SingleSelect
                  name={"selectcategory"}
                  label={
                    "Are You Intending to Import Weights, Measures etc from Places outside The Country?"
                  }
                  isRequired={true}
                  dataOptions={[
                    {
                      label: "Category",
                      value: "category",
                    },
                  ]}
                  onDataChange={(value) => {}}
                />
                <DropdownSelect2SingleSelect
                  name={"selectcategory"}
                  label={
                    "Have You Applied Previously For a Dealer's License Either in The State or Elsewhere?"
                  }
                  isRequired={true}
                  dataOptions={[
                    {
                      label: "Category",
                      value: "category",
                    },
                  ]}
                  onDataChange={(value) => {}}
                />

                <InputForm
                  label="Registration Number of Importer of Weights and Measures, if Any?"
                  name="regno"
                  // isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter reg. no."
                ></InputForm>
                <InputForm
                  label="Approval of Modal Imported Into India by Central Government"
                  name="approval"
                  // isRequired={true}
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter approval here"
                ></InputForm>
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="upload-document">
          <Card className="border-none shadow-none text-sm">
            <CardContent className="mt-10">
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                <InputForm
                  label="Agreement With Suppliers"
                  dataChange={(value) => {}}
                  name="agreement"
                  type="file"
                  isRequired={true}
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Commercial Electricity"
                  name="pan-card"
                  type="file"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Current Trade Licences"
                  name="certification"
                  type="file"
                  isRequired
                  dataChange={() => {}}
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="GST"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Income Tax"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Land Conversion Certificate"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Model Approval Certificate"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="PAN/EPIC"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Partnership Deed / EM-1/ Form-32"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Professional Tax Receipt"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Rent / Ownership"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Space Suitablity Certificate (Schematic Diagram)"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Specimen Signature"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
      </Tabs>
    </section>
  );
}
