import EnforcementMain from "../Components/EmforcementComponent/EnforcementMain";
import NewFooter from "../Components/Footer/NewFooter";
import Navbar from "../Components/Navbar/Navbar";

const EnforcementPage = () => {
  return (
    <>
      <Navbar />
      <EnforcementMain />
      <div className="pt-36 md:pt-20 bg-theme_bg"></div>
      <NewFooter />
    </>
  );
};

export default EnforcementPage;
