import React, { Suspense } from "react";
import "./Utils/i18n";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import LoadingScreen from "./Components/loadingscreen/loading.component";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<LoadingScreen />}>
        <App />
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>
);

reportWebVitals();
