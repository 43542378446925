import { Button } from '../ui/button'
import img from "../../Assets/image/expandsection/1.jpg"
import UserNotification from './UserNotification'
import { PiCertificateFill } from "react-icons/pi";
import { RiFileEditLine } from "react-icons/ri";
import { TiDocumentText } from "react-icons/ti";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GrDocumentMissing } from "react-icons/gr";

function UserInfo() {
  return (
  <>
    <div className='w-full flex items-center justify-between'>
        <div className='flex flex-row items-center gap-8'>
            
            <div className='flex items-center justify-center size-28 rounded-full overflow-hidden'>
                <img src={img} className='w-full aspect-square' alt='DP'/>
                <button className='text-xs ml-16 absolute border-dashed border-2 border-green-500 bg-[#E9FFD2] mb-[70px] rounded-md shadow-md'>+Add Logo</button>
            </div>
            
            
            <div>
            <h3 className='tracking-wider'>DP Iron Industries</h3>
            <p className='text-gray-600'>Iron Making and Moulding</p>
            </div>
        </div>
        <Button className='py-6 px-8 font-semibold tracking-wider'>+ New BUSINESS REQUEST</Button>
    </div>


    <div className='w-full flex items-start gap-6 mt-10'>
        
        {/* //draft application boxes...... */}
        <div className='w-1/2 h-full'>
        
        <div className='grid grid-cols-2 gap-5 text-white  tracking-wide'>

          <div className='bg-gradient-to-r from-[#48BCF8] to-[#4394EF] p-5 rounded-lg flex flex-col gap-3'>
            <h5 className='flex items-center gap-2 font-semibold'><RiFileEditLine className='bg-white bg-opacity-25 rounded-full p-[7px]' size={30}/> Draft<br></br> Application</h5>
            <h1>1</h1>
          </div>
          <div className='bg-gradient-to-r from-[#6457FE] to-[#4C3DDB] p-5 rounded-lg flex flex-col gap-3'>
            <h5 className='flex items-center gap-2 font-semibold'><TiDocumentText className='bg-white bg-opacity-25 rounded-full p-[7px]' size={30}/>Applications<br></br> Submitted</h5>
            <h1>6</h1>
          </div>
          <div className='bg-gradient-to-r from-[#AE4BFD] to-[#9109FE] p-5 rounded-lg flex flex-col gap-3'>
            <h5 className='flex items-center gap-2 font-semibold'><PiCertificateFill className='bg-white bg-opacity-25 rounded-full p-[7px]' size={30}/>Licence Issued</h5>
            <h1>2</h1>
          </div>
          <div className='bg-gradient-to-r from-[#E672FE] to-[#C32AE3] p-5 rounded-lg flex flex-col gap-3'>
            <h5 className='flex items-center gap-2 font-semibold'><IoDocumentTextOutline className='bg-white bg-opacity-25 rounded-full p-[7px]' size={30}/>Applicaitons<br></br> Pending</h5>
            <h1>3</h1>
          </div>
          <div className='bg-gradient-to-r from-[#FF5252] to-[#F00202] p-5 rounded-lg flex flex-col gap-3'>
            <h5 className='flex items-center gap-2 font-semibold'><GrDocumentMissing className='bg-white bg-opacity-25 rounded-full p-[7px]' size={30}/>Application<br></br> Rejected</h5>
            <h1>1</h1>
          </div>
        </div>
        
        </div>
        
        
        {/* //Notifications..... */}
        <UserNotification />
        {/* <div className='w-1/2 theme-box-shadow rounded p-4'>
        <div className='flex items-center gap-3'>
        <FaRegBell className='text-theme_yellow size-5'/>    
        <h5 className='font-semibold tracking-wide'>Notifications</h5>
        </div>
        </div> */}
    </div>

    </>
  )
}

export default UserInfo
