import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import image1 from "../../Assets/image/icons/fastandsecure.svg"
import image2 from "../../Assets/image/icons/fastprocessing.svg"

import image3 from "../../Assets/image/icons/realtime-status.svg"

const dummy = [
  {
    image: image1,
    name: "secureEasy",
    description: "secureEasyDescription",
  },
  {
    image: image2,
    name: "fastProcessing",
    description: "fastProcessingDescription",
  },
  {
    image:image3,
    name: "realTime",
    description: "realTimeDescription",
  },
];

export default function ServiceComponent() {

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <section className="bg-white lg:bg-gradient-to-r from-theme_bg from-25% to-white to-25% -mt-10">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center place-content-center relative z-[2]">
          {dummy.map((item, index) => (
            <div
              className="grid grid-rows-subgrid row-span-3 gap-2 shadow-lg p-4 bg-white w-full max-w-[422px]"
              key={index}
            >
              <div>
                <div className="p-2 bg-black size-[48px] flex items-center">
                  <img
                    className="object-center m-auto"
                    src={item.image}
                    width={28}
                    height={28}
                    alt={item.name}
                  />
                </div>
              </div>
              <h4>{t(item.name)}</h4>
              <p className="text-paragraph max-w-[40ch]">{t(item.description)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
