"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../../../../lib/utils";
import { Calendar } from "../../../../../Components/ui/calendar";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../Components/ui/popover";

import { ChangeEvent, useEffect, useState } from "react";

import HelpForm from "../../help.form";
import RequiredTemplate from "../../required.form";

export default function SingleDatePicker({
  label,
  id,
  isRequired = false,
  name,
  value,
  className,
  dataChange,
  error,
  correct,
  helpText,
  placeholder = "Pick a date",
  min,
  max,
}: {
  id?: string;
  label?: string;
  isRequired?: boolean;
  name: string;
  value?: Date;
  className?: string;
  dataChange: (value: Date | undefined) => void;
  error?: string;
  correct?: string;
  isLoading?: boolean;
  helpText?: string;
  placeholder?: string;
  min?: number;
  max?: number;
}) {
  const [date, setDate] = React.useState<Date | undefined>(value);
  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);

  const handleDataChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    changeEvent.preventDefault();
    dataChange(date);
  };

  return (
    <Popover onOpenChange={setPopOverOpen} open={popOverOpen}>
      <PopoverTrigger asChild>
        <div className={cn("grid w-full items-center  gap-1.5", className)}>
          {label && (
            <div className="flex flex-row items-center gap-2">
              <label className="font-bold" htmlFor={name}>
                {label}
                 <RequiredTemplate required={isRequired} />
              </label>
              {helpText && <HelpForm helpText={helpText} />}
            </div>
          )}
          <div className="relative">
            <input
              readOnly={true}
              placeholder={placeholder}
              id={id}
              type={"text"}
              name={name}
              className={cn(
                "w-full py-1.5  border border-gray-300  " +
                  "focus:outline-theme_yellow dark:bg-[#1b1b29] dark:text-white px-2 rounded",
                popOverOpen ? "outline outline-2 outline-theme_yellow" : "",
                error ? "ring-1 ring-red-600" : "",
                !error && correct ? "ring-1 ring-green-600" : ""
              )}
              value={date ? format(date, "dd/MM/yyyy") : ""}
              onChange={(value) => handleDataChange(value)}
              required={isRequired}
              min={min}
              max={max}
            />
            <div className="absolute top-2 right-2">
              <CalendarIcon className="size-4 text-theme_yellow" />
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
        //   initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
