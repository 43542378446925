
import SideAndHeadComponent from '../../Header/SideAndHeadComponent'

function UpdateLicence() {
  return (
    <SideAndHeadComponent>
      yhan hum apna licence update krenge
    </SideAndHeadComponent>
  )
}

export default UpdateLicence
