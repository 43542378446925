import { FaRegBell } from 'react-icons/fa'
import { FaRegClock } from "react-icons/fa6";

const notifications = [
  {
    title:"Your application for New License has been submitted successfully!",
    date:"Mar 12 | 12:32",
  },
  {
    title:"Your application for New License has been submitted successfully!",
    date:"Mar 12 | 12:32",
  },
  {
    title:"Your application for New License has been submitted successfully!",
    date:"Mar 12 | 12:32",
  }
]

function UserNotification() {
  return (
    <>
      <div className='w-1/2 theme-box-shadow rounded-lg'>
        
        {/* Notification heading.... */}
        <div className='flex items-center gap-3 p-4'>
        <FaRegBell className='text-theme_yellow size-5'/>    
        <h5 className='font-semibold tracking-wide'>Notifications</h5>
        </div>

        {/* Notifications..... */}

        {
          notifications.map((notify, index)=>(
            <div key={index} className='px-4 py-2 border-t-2 tracking-wide text-gray-500'>
              <p className=''>{notify.title}</p>
              <div className='flex items-center gap-3 justify-end'><FaRegClock /><p className='text-sm text-gray-700'>{notify.date}</p></div>
            </div>
          ))
        }
        </div>
    </>
  )
}

export default UserNotification
