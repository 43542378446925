import React from "react";

import { InputForm } from "../Helper/form/input.form";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

import { Select } from "@radix-ui/react-select";

import DropdownSelect2SingleSelect from "../Helper/form/select2/select.dropdown";

function LicenseRegistrationComponent() {
  return (
    <section className="container section-padding">
      <Tabs defaultValue="personal-information" className="text-center">
        <TabsList className="bg-[#EFF0F4] rounded-full">
          <TabsTrigger value="personal-information">
            PERSONAL INFORMATION
          </TabsTrigger>
          <TabsTrigger value="business-information">
            BUSINESS INFORMATION
          </TabsTrigger>
          <TabsTrigger value="upload-document">UPLOAD DOCUMENT</TabsTrigger>
        </TabsList>
        <TabsContent value="personal-information">
          <Card className="border-none shadow-none">
            <CardContent className="mt-10">
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="Name"
                  dataChange={(value) => {}}
                  name="name"
                  type="text"
                  isRequired={true}
                  placeholder="Enter your name"
                ></InputForm>
                <InputForm
                  label="Email"
                  name="email"
                  type="email"
                  dataChange={() => {}}
                  isRequired
                  placeholder="Enter your email"
                ></InputForm>
                <InputForm
                  label="Mobile"
                  name="mobile"
                  type="number"
                  isRequired
                  dataChange={() => {}}
                  placeholder="Enter your number"
                ></InputForm>
                <InputForm
                  label="House No./Building/Flat"
                  type="text"
                  name="house_no"
                  dataChange={() => {}}
                  isRequired
                  placeholder="For e.g. A-34, Building name"
                ></InputForm>
                <InputForm
                  label="Block/Street/Maholla/Sector"
                  name="sector"
                  dataChange={() => {}}
                  type="text"
                  isRequired
                  placeholder="For e.g. Sector 32"
                ></InputForm>
                <InputForm
                  label="Area/Suburb"
                  name="area"
                  dataChange={() => {}}
                  type="text"
                  placeholder="For e.g. Chandigarh"
                ></InputForm>
                <InputForm
                  label="Landmark/Locality"
                  name="landmark"
                  dataChange={() => {}}
                  type="text"
                  placeholder="For e.g. near Railway station"
                ></InputForm>
                <InputForm
                  label="PIN Code"
                  name="pin_code"
                  dataChange={() => {}}
                  type="number"
                  isRequired
                  placeholder="Enter your PIN Code"
                ></InputForm>

                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"state"}
                  label={"State"}
                  isRequired ={true}
                  dataOptions={[
                    {
                      label: "Maharastra",
                      value: "maharastra",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />

                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"district"}
                  label={"District"}
                  isRequired
                  dataOptions={[
                    {
                      label: "District",
                      value: "district",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />

                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"post-office"}
                  label={"Post Office"}
                  isRequired
                  dataOptions={[
                    {
                      label: "Post-Office",
                      value: "post-office",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="business-information">
          <Card className="border-none shadow-none">
            <CardContent className="mt-10">
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <InputForm
                  label="Title"
                  dataChange={(value) => {}}
                  name="title"
                  type="text"
                  isRequired={true}
                  placeholder="Enter your Business Title"
                ></InputForm>
                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"business-type"}
                  label={"Business Type"}
                  isRequired
                  dataOptions={[
                    {
                      label: "ABC",
                      value: "abc",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />
                <InputForm
                  label="GSTIN Number"
                  name="gstin-number"
                  type="number"
                  isRequired
                  dataChange={() => {}}
                  placeholder="Enter your GSTIN number"
                ></InputForm>
                <InputForm
                  label="Phone Number"
                  type="number"
                  name="phone_no"
                  dataChange={() => {}}
                  placeholder="Enter your business Phone number"
                ></InputForm>
                <InputForm
                  label="Email"
                  name="email-business"
                  dataChange={() => {}}
                  type="email"
                  placeholder="Enter your business Email"
                ></InputForm>
                <DropdownSelect2SingleSelect
className='text-left'
                name={"designation"}
                label={"Designation"}
                isRequired
                dataOptions={[
                  {
                    label: "XYZ",
                    value: "xyz",
                  },
                ]}
                onDataChange={(value: any) => {}}
              />
                <InputForm
                  label="House No./Building/Flat"
                  type="text"
                  name="house_no"
                  dataChange={() => {}}
                  isRequired
                  placeholder="For e.g. A-34, Building name"
                ></InputForm>
                <InputForm
                  label="Block/Street/Maholla/Sector"
                  name="sector"
                  dataChange={() => {}}
                  type="text"
                  isRequired
                  placeholder="For e.g. Sector 32"
                ></InputForm>
                <InputForm
                  label="Area/Suburb"
                  name="area"
                  dataChange={() => {}}
                  type="text"
                  placeholder="For e.g. Chandigarh"
                ></InputForm>
                <InputForm
                  label="Landmark/Locality"
                  name="landmark"
                  dataChange={() => {}}
                  type="text"
                  placeholder="Enter your name"
                ></InputForm>
                <InputForm
                  label="PIN Code"
                  name="pin_code"
                  dataChange={() => {}}
                  type="number"
                  isRequired
                  placeholder="Enter your name"
                ></InputForm>
                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"state"}
                  label={"State"}
                  isRequired = {true}
                  dataOptions={[
                    {
                      label: "Maharastra",
                      value: "maharastra",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />

                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"district"}
                  label={"District"}
                  isRequired
                  dataOptions={[
                    {
                      label: "District",
                      value: "district",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />

                <DropdownSelect2SingleSelect
                  className="text-left"
                  name={"post-office"}
                  label={"Post Office"}
                  isRequired
                  dataOptions={[
                    {
                      label: "Post-Office",
                      value: "post-office",
                    },
                  ]}
                  onDataChange={(value: any) => {}}
                />
                
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="upload-document">
          <Card className="border-none shadow-none">
            <CardContent className="mt-10">
              <div className="gap-3 grid grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                <InputForm
                  label="Adhar"
                  dataChange={(value) => {}}
                  name="adhar"
                  type="file"
                  isRequired={true}
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="PAN Card"
                  name="pan-card"
                  type="file"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Certification"
                  name="certification"
                  type="file"
                  isRequired
                  dataChange={() => {}}
                  placeholder=""
                ></InputForm>
                <InputForm
                  label="Any other Attachment"
                  type="file"
                  name="other-attachment"
                  dataChange={() => {}}
                  isRequired
                  placeholder=""
                ></InputForm>
              </div>
            </CardContent>
            <CardFooter className="flex flex-row justify-between mt-8">
              <button className="px-10 py-3 bg-[#D4D9E5] rounded-md">
                PREVIOUS
              </button>
              <button className="px-10 py-3 bg-theme_yellow rounded-md">
                NEXT
              </button>
            </CardFooter>
          </Card>
        </TabsContent>
      </Tabs>
    </section>
  );
}

export default LicenseRegistrationComponent;
