import React from 'react'
import ActsAndRulesComponent from '../Components/ActAndRules/ActsAndRulesComponent'
import Navbar from '../Components/Navbar/Navbar'
import NewFooter from '../Components/Footer/NewFooter'

const ActAndRulesPage = () => {
  return (
    <>
     <Navbar/>
      <ActsAndRulesComponent/>
      <NewFooter />
    </>
  )
}

export default ActAndRulesPage