import AxiosUtils from "../../Utils/axios.utils";


export type TActAndRulesData = {
    name: string
    link: string
    category: string
    id:number
    file_path:string
}

export const GetActsAndRules = async ()=>{
    try {
        const response  = await AxiosUtils.get("/api/actAndRules")
        if (response.status != 200) {
            throw new Error("error while fetch")
        }
        return response.data.data as TActAndRulesData[]
    }catch (e) {
        console.log(e)
        return []
    }

}