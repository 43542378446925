import React, { ChangeEvent } from "react";
// import { RequiredTemplate } from "./required.form";
// import RequiredTemplate  from "./required.form";
// import RequiredTemplate from "../form/required.form";/
import RequiredTemplate from "../../Helper/form/required.form";
import { cn } from "../../../lib/utils";
import { ImSpinner6 } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { VscEye } from "react-icons/vsc";


export function InputForm({
  label,
  id,
  isRequired = false,
  name,
  type,
  value,
  className,
  dataChange,
  readonly = false,
  error,
  correct,
  dataBlur,
  isLoading,
  helpText,
  placeholder,
  min,
  max,
  labelcolor,
  showpassword,
  passvisble,
  eyebutton,
}: {
  id?: string;
  label?: string;
  isRequired?: boolean;
  name: string;
  type: string;
  value?: any;
  className?: string;
  dataChange: (value: any) => void;
  readonly?: boolean;
  error?: string;
  correct?: string;
  dataBlur?: (value: any) => void;
  isLoading?: boolean;
  helpText?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  labelcolor?: string;
  showpassword?: (val: boolean) => void;
  passvisble?: boolean;
  eyebutton?: boolean;

}) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (type === "file") {
      if (e.target.files) {
        dataChange(e.target.files[0]);
      } else {
        dataChange(null);
      }
    } else {
      const value = e.target.value;
      dataChange(value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (dataBlur) {
      dataBlur(e);
    }
  };

  return (
    <>
    <div className={cn("grid w-full items-center  gap-1.5", className)}>
      {label && (
        <div className="flex flex-row items-center gap-2 font-semibold">
          <label htmlFor={name} className={cn(labelcolor)}>
            {label}
            <RequiredTemplate required={isRequired} />
          </label>
          {/* {helpText && <HelpForm helpText={helpText} />} */}
        </div>
      )}
      <div className="relative">
        <input
          readOnly={readonly}
          placeholder={placeholder}
          id={id}
          type={type}
          name={name}
          className={cn(
            "w-full py-3 border-2 dark:bg-[#1b1b29] dark:text-white px-2 rounded-md",
            error ? "ring-1 ring-red-600" : "",
            !error && correct ? "ring-1 ring-green-600" : ""
          )}
          value={value}
          onChange={handleChange}
          required={isRequired}
          onBlur={handleBlur}
          // min={}
          // max={}
        />
        {eyebutton && (
          <div
            onClick={() => {
              showpassword && showpassword(!passvisble);
            }}
            className="absolute cursor-pointer top-3 right-2"
          >
            <VscEye className="h-7 w-7" />
          </div>
        )}
        <div className="absolute top-2 right-2">
          {isLoading && (
            <ImSpinner6 size={20} className="text-sky-700 animate-spin" />
          )}
          {!isLoading && !error && correct && (
            <TiTick size={20} className="text-green-500"></TiTick>
          )}
        </div>
      </div>

      {error && (
        <div className="text-red-600 text-sm font-medium">{error}</div>
      )}
      {!error && correct && (
        <div className="text-green-600 text-sm font-medium">{correct}</div>
      )}
    </div>
  </>
  );
}
