
import SideAndHeadComponent from '../../Header/SideAndHeadComponent'

function RenewLicence() {
  return (
    <SideAndHeadComponent>
      Yhan hum apna purana loicence renew krengeee.....
    </SideAndHeadComponent>
  )
}

export default RenewLicence
