
import SideAndHeadComponent from '../../Header/SideAndHeadComponent'
import NewLicensePage from "../../LicenceComponents/NewLicenseComponent"

function NewLicence() {
  return (
    <SideAndHeadComponent>
      <NewLicensePage />
    </SideAndHeadComponent>
  )
}

export default NewLicence
