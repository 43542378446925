import { ReportsMockData, TReportMockMonthly } from "../../Utils/reports.mock";
import { DataTableInfo } from "../datatables/DataTables";
import { GetReportData, TReportData, TReportPayload } from "./reports.services";
import { useEffect, useState } from "react";

export default function ReportsHook() {
  const serviceOptions = [
    "All",
    "Modal Approval System",
    "Certificates of Importers of Weights & Measures",
    "Directors of the Companies Nominated",
    "Packaged Commodities Registration",
  ];

  const DivisionData = ["All"];

  const PeriodData = [
    // "Custom",
    "Yearly",
    "Monthly",
    // "Quarterly",
    // "Fortnightly"
  ];
  const generateMonths = (
    mode: "long" | "short" | "numeric" = "long",
    startMonth: number = 0,
    endMonth: number = 11
  ) => {
    const monthArray: { name: string; number: number }[] = [];
    for (let i = startMonth; i <= endMonth; i++) {
      const name = new Date(0, i).toLocaleString("en-US", { month: mode });
      monthArray.push({
        name,
        number: i,
      });
    }
    return monthArray;
  };

  const generatedYears = (
    startYear: number = 2018,
    endYear: number = new Date().getFullYear()
  ) => {
    const yearArray: number[] = [];
    for (let i = endYear; i >= startYear; i--) {
      yearArray.push(i);
    }
    return yearArray;
  };

  const [services, setServices] = useState<string>();
  const [states, setStates] = useState<string>();
  const [period, setPeriod] = useState<string>();
  const [months, setMonth] = useState<string>();
  const [year, setYear] = useState<string>();
  const [stateName, setStateName] = useState<string>();

  const [toDate, setToDate] = useState<Date>();
  const [fromDate, setFromDate] = useState<Date>();

  const [showDataTable, setShowDataTable] = useState(false);
  const [loading, setloading] = useState(false);

  const [dataTableData, setDatatableData] = useState<TReportData[]>([]);

  const StatesData = () => {
    return [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Uttar Pradesh",
        value: "9",
      },
      {
        label: "Maharashtra",
        value: "27",
      },
      {
        label: "Bihar",
        value: "10",
      },
      {
        label: "Chandigarh",
        value: "4",
      },
      {
        label: "Rajasthan",
        value: "8",
      },
      {
        label: "Punjab",
        value: "3",
      },
      // {
      //     label: "Andhra Pradesh",
      //     value: "28"
      // },
      {
        label: "Karnataka",
        value: "29",
      },
      {
        label: "Haryana",
        value: "6",
      },
      {
        label: "Puducherry",
        value: "34",
      },
      {
        label: "Chhattisgarh",
        value: "22",
      },
    ];
  };

  const getLabelByValue = (value: string) => {
    const states = StatesData();
    const state = states.find((state) => state.value === value);
    setStateName(state?.label);
  };

  useEffect(() => {
    setMonth(undefined);
    setYear(undefined);
  }, [period]);
  const MonthData = () => {
    let data;
    if (year && parseInt(year) == new Date().getFullYear()) {
      data = generateMonths("long", 0, new Date().getMonth() - 1).map((x) => ({
        label: x.name,
        value: String(x.number),
      }));
    } else {
      data = generateMonths("long", 0, 11).map((x) => ({
        label: x.name,
        value: String(x.number),
      }));
    }
    return data;
  };

  const YearData = (): { label: string; value: string }[] => {
    const data = [];
    if (period == "yearly") {
      data.push({
        label: "All",
        value: "all",
      });
    }
    data.push(
      ...generatedYears().map((x) => ({
        label: String(x),
        value: String(x),
      }))
    );
    return data;
  };

  useEffect(() => {
    const date = new Date();
    if (year && year.trim() != "" && year.trim() != "all") {
      date.setFullYear(parseInt(year));
    }

    if (months && months.trim() != "") {
      date.setMonth(parseInt(months));
    }

    if (period == "monthly") {
      setFromDate(new Date(date.getFullYear(), date.getMonth(), 1));
      setToDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    } else if (period == "yearly") {
      if (year && year.trim() == "all") {
        setFromDate(undefined);
        setToDate(undefined);
      } else {
        setFromDate(new Date(date.getFullYear(), 0, 1));
        setToDate(new Date(date.getFullYear(), 11, 31));
      }
    }
  }, [months, year]);

  const disableSubmitButton = () => {
    if (period == "yearly") {
      return !(year && states);
    } else if (period == "monthly") {
      return !(year && months && states);
    }
    return true;
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) {
      return undefined;
    }
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    return year + "-" + month + "-" + day;
  };
  const handleSubmit1 = async (value: any) => {
    const payload: any = {
      start_date: formatDate(fromDate),
      end_date: formatDate(toDate),
      state_lgd_code:
        states && states.trim() != "" && states.trim() != "all"
          ? parseInt(states)
          : undefined,
    };
    Object.keys(payload).forEach((key) =>
      payload[key] === undefined ? delete payload[key] : {}
    );

    const reportData = await GetReportData(payload);
    if (states) {
      getLabelByValue(states);
    }

    // const data =
    //     ReportsMockData.find(
    //         (x) =>
    //             String(x.month.getMonth()) == months &&
    //             String(x.month.getFullYear()) == year
    //     )?.data || ([] as TReportMockMonthly[]);
    setDatatableData(reportData);
    setShowDataTable(true);
  };
  const handleSubmit = async (value: any) => {
    setloading(true);
  
    const payload: any = {
      start_date: formatDate(fromDate),
      end_date: formatDate(toDate),
      state_lgd_code:
        states && states.trim() !== "" && states.trim() !== "all"
          ? parseInt(states)
          : undefined,
    };
  
    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined) delete payload[key];
    });
  
    try {
      const reportData = await GetReportData(payload);
  
      if (states) {
        getLabelByValue(states);
      }
  
      setDatatableData(reportData);
      setShowDataTable(true);
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setloading(false);
    }
  };
  
  
  const dataTableInfo: DataTableInfo = {
    name: "Reports Table",
    columnInfo: [
      {
        header: "Application Category",
        field: "applicationName",
        filter: true,
      },
      {
        header: "Service Name",
        field: "serviceName",
        filter: true,
      },
      {
        header: `Notified Timeline Under State's Acts & Rule \n In Days`,
        field: "notifiedTimeline",
      },

      {
        header: "Total Applications Received",
        field: "applicationReceived",
      },
      {
        header: "Applications Approved",
        field: "applicationApproved",
      },
      {
        header: "Maximum Days Taken",
        field: "maxDaysTaken",
      },
      {
        header: "Minimum Days Taken",
        field: "minDaysTaken",
      },
    ],
  };
  return {
    serviceOptions,
    StatesData,
    DivisionData,
    PeriodData,
    services,
    setServices,
    months,
    setMonth,
    MonthData,
    year,
    setYear,
    period,
    setPeriod,
    states,
    setStates,
    showDataTable,
    setShowDataTable,
    handleSubmit,
    dataTableData,
    YearData,
    disableSubmitButton,
    dataTableInfo,
    stateName,
    setStateName,
    loading
  };
}

function setError(arg0: string) {
  throw new Error("Function not implemented.");
}
