import image1 from "../../Assets/image/background/stay-updated-1.jpg";
import image2 from "../../Assets/image/background/stay-updated-2.jpg";
import image3 from "../../Assets/image/background/stay-updated-3.jpg";
import { MdOutlineArrowRightAlt } from "react-icons/md";

export default function StayUpdatedComponent() {
  const stayUpdatedData = [
    {
      name: "Amendments made in legal metrology act for automobiles",
      description:
        "In case you face any difficulty with regard to implementation of the provisions of Legal Metrology Act or Rules framed.",
      image: image1,
      link: "",
      date: "2024-03-23",
    },
    {
      name: "“Quality is our topmost priority”...said by hon’ble DG",
      description:
        "In case you face any difficulty with regard to implementation of the provisions of Legal Metrology Act or Rules framed.",
      image: image2,
      link: "",
      date: "2024-03-23",
    },
    {
      name: "Laboratory equipment should be highly tested",
      description:
        "In case you face any difficulty with regard to implementation of the provisions of Legal Metrology Acts and Rules framed.",
      image: image3,
      link: "",
      date: "2024-03-23",
    },
  ];
  return (
    <section id="stay_updated" className="bg-white section-padding">
      <div className="container">
        <h1 className="text-center mx-auto">
          Stay <span className="text-theme_yellow">Updated </span>
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 section-margin gap-4 place-items-center place-content-center">
          {stayUpdatedData.map((item, index) => (
            <div
              className="w-full lg:max-w-[420px] border border-[#EFF0F4] grid grid-rows-subgrid row-span-4"
              key={index}
            >
              <div className="w-full max-h-[377px] aspect-[0.8] bg-gray-600 overflow-hidden relative">
                <div className="absolute flex flex-col bg-theme_yellow justify-center text-center p-1 xl:p-2 top-4 right-4 font-medium">
                  <div>
                    {new Date(item.date).toLocaleDateString("default", {
                      day: "numeric",
                    })}
                  </div>
                  <div className="uppercase">
                    {new Date(item.date).toLocaleDateString("default", {
                      month: "short",
                    })}
                  </div>
                </div>
                <img
                  className="object-center object-fill w-full h-full"
                  src={item.image}
                  alt={item.name}
                  height={377}
                  width={420}
                />
              </div>
              <h3 className="px-4 mt-2">{item.name}</h3>
              <p className="px-4 mt-2 text-paragraph">{item.description}</p>
              <div className="bg-[#F4F5F8] mt-2 px-4 py-2">
                <a
                  href={item.link}
                  className="flex flex-row gap-2 items-center text-[#727272] "
                >
                  READ MORE <MdOutlineArrowRightAlt size={20} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
