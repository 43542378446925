import ReportBg from "../../Assets/image/background/reports-bg.jpg";
import { renderSkeletonTable } from "../Helper/SkeltonBody/SkeltonBody";
import DropdownSelect2SingleSelect, {
  CreateOptionMultiSelectOptionOnlyLabel,
} from "../Helper/form/select2/select.dropdown";
import PageHeaderComponent from "../Helper/pageHeader/pageHeader.component";
import DataTables from "../datatables/DataTables";
import { Button } from "../ui/button";
import ReportsHook from "./reports.hook";
export default function ReportMainComponent() {
  const {
    StatesData,
    PeriodData,
    months,
    setMonth,
    year,
    setYear,
    MonthData,
    period,
    setPeriod,
    setStates,
    states,
    showDataTable,
    handleSubmit,
    dataTableData,
    YearData,
    disableSubmitButton,
    dataTableInfo,
    stateName,
    loading, 
  } = ReportsHook();

  return (
    <>
      <PageHeaderComponent imageUrl={ReportBg}>Reports</PageHeaderComponent>
      <section className="container section-margin pb-20">
        <div className="p-8 theme-box-shadow rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
            <DropdownSelect2SingleSelect
              name={"state"}
              label={"State"}
              selectedData={states}
              dataOptions={StatesData()?.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              onDataChange={(value) => setStates(value)}
            />
            <DropdownSelect2SingleSelect
              name={"period"}
              label={"Period"}
              selectedData={period}
              dataOptions={PeriodData.map((x) =>
                CreateOptionMultiSelectOptionOnlyLabel(x)
              )}
              onDataChange={setPeriod}
            />
            {period && (period == "monthly" || period == "yearly") && (
              <DropdownSelect2SingleSelect
                name={"year"}
                label={"Year"}
                selectedData={year}
                dataOptions={YearData()}
                onDataChange={setYear}
              />
            )}
            {period && year && period == "monthly" && (
              <DropdownSelect2SingleSelect
                name={"month"}
                label={"Month"}
                dataOptions={MonthData()}
                selectedData={months}
                onDataChange={setMonth}
              />
            )}
          </div>
          <div className="flex justify-end mt-8">
            <Button
              onClick={(event) => handleSubmit(event)}
              size="long"
              disabled={disableSubmitButton()}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </section>
      {loading
        ? renderSkeletonTable()
        : showDataTable && (
            <section className="container pb-[3.125rem] ">
              <div className="theme-box-shadow rounded-lg">
                <DataTables
                  states={states}
                  table_info={dataTableInfo}
                  loading={loading}
                  data={dataTableData}
                  groupBy={"applicationName"}
                />
              </div>
              <div className="container flex justify-center my-6">
                <p className="text-sm text-[#3f3f46]">
                  <strong>Disclaimer: </strong>All data is provisioned by the
                  States. In case of any discrepancies, please get in touch with
                  the CLM (Controller of Legal Metrology) of the{" "}
                  {stateName === "All" ? "respective States" : stateName}.
                </p>
              </div>
            </section>
          )}
    </>
  );
}
