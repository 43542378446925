import {useState}  from 'react'
import SideAndHeadComponent from '../../Header/SideAndHeadComponent'
import NavigateButton from '../../../Components/Dashboard/Buttons/NavigateButton';


function Licence() {

    const tabs = [
        { label: "NEW/FRESH APPLICATION", url:"/licence/new_licence" },
        { label: "UPDATE LICENCE", url:"/licence/update_licence" },
        { label: "RENEW LICENCE", url:"/licence/renew_licence" },
    ];


    const [activeTab, setActiveTab] = useState<number>(0);

    const forTabs = [
        {lable: "DEALER"},
        {lable: "MANUFRATURE"},
        {lable: "REPAIRER"},
    ];

  return (
    <SideAndHeadComponent>
        
        <div>
          <h4>Licence</h4>
          <p className='font-semibold my-4'>For<span className='text-[red]'>*</span></p>
          
          <div className="flex gap-4">
                {forTabs.map((item,index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`px-8 py-2 rounded-3xl transition-colors duration-300 border-2 text-sm border-black $${activeTab === index ? " bg-black text-white" : "text-black"
                        }`}
                    >
                        {item.lable}
                    </button>
                ))}
            </div>


          <p className='font-semibold my-4'>Apply As<span className='text-[red]'>*</span></p>
          <NavigateButton tabs={tabs}/>
          
        </div>
      
      
    </SideAndHeadComponent>
  )
}

export default Licence