import React from "react";
import styles from "./states.module.scss";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../lib/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import bgMaharashtra from "../../Assets/image/states/maharashtra.svg";
import bgKarnataka from "../../Assets/image/states/karnataka.svg";
import bgJharkhand from "../../Assets/image/states/jharkhand.png";
import bgUttar_pradesh from "../../Assets/image/states/uttar-pradesh.svg";
import bgAndhra_pradesh from "../../Assets/image/states/andhra-pradesh.svg";
import bgTamilnadu from "../../Assets/image/states/tamilnadu.png"
import bgPunjab from "../../Assets/image/states/punjab.png"
import bgUttarakhand from "../../Assets/image/states/uttarakhand.png"
import bgRajasthan from "../../Assets/image/states/Raj.png"
import bgBihar from "../../Assets/image/states/bihar.png"
import bgChandigarh from "../../Assets/image/states/chandigarh.png"
import linkIcon from "../../Assets/image/icons/link-icon.svg";

export default function StatesComponent() {

  

  const states = [
    {
      name: "maharashtra",
      description: "stateDescription",
      bgColor: "#F37527",
      bgImage: bgMaharashtra,
      link: "https://www.vaidhmapan.maharashtra.gov.in/",
    },
    {
      name: "uttarPradesh",
      description: "stateDescription",
      bgColor: "#8D36FF",
      bgImage: bgUttar_pradesh,
      link: "https://legalmetrology-up.gov.in/metrology/index.php#:~:text=In%20Uttar%20Pradesh%2C%20the%20department%20of%20Legal%20Metrology%20has%20been,is%20presently%20heading%20the%20department.",
    },
    
    {
      name: "Bihar",
      description: "stateDescription",
      bgColor: "#F3A338",
      bgImage: bgBihar,
      link: "https://maaptaul.bih.nic.in/lmd/",
    },
    {
      name: "Rajasthan",
      description: "stateDescription",
      bgColor: "#F37527",
      bgImage: bgRajasthan,
      link: "https://legalmetrology.rajasthan.gov.in/",
    },
    {
      name: "Chandigarh",
      description: "stateDescription",
      bgColor: "#16ABFE",
      bgImage: bgChandigarh,
      link: "https://etula.chdfood.gov.in/",
    },
    
    {
      name: "Punjab",
      description: "stateDescription",
      bgColor: "#F37527",
      bgImage: bgPunjab,
      link: "https://www.emapan.punjab.gov.in/HomePages/login.aspx",
    },
    {
      name: "Karnataka",
      description: "stateDescription",
      bgColor: "#0145EB",
      bgImage: bgKarnataka,
      link: "https://emapan.karnataka.gov.in/",
    },
   
    {
      name: "Tamilnadu",
      description: "stateDescription",
      bgColor: "#F3A338",
      bgImage: bgTamilnadu,
      link: "https://labour.tn.gov.in/",
    },
    
  
    {
      name: "AndhraPradesh",
      description: "stateDescription",
      bgColor: "#16ABFE",
      bgImage: bgAndhra_pradesh,
      link: "https://aplm.gov.in/",
    },
   
    {
      name: "Uttarakhand",
      description: "stateDescription",
      bgColor: "#0145EB",
      bgImage: bgUttarakhand,
      link: "https://fcs.uk.gov.in/pages/display/73-legal-metrology-(wm)",
    },
   
    {
      name: "Jharkhand",
      description: "stateDescription",
      bgColor: "#8D36FF",
      bgImage: bgJharkhand,
      link: "https://elegalmetrology.jharkhand.gov.in/japnet/ConsumersAppliedReport.aspx",
    },
  
    
  ];


  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <section className={cn(styles.stateBG, "section-padding bg-white")}>
      <div className="container">
        <h1 className="max-w-[25ch] text-center mx-auto">
          {/* Select <span className="text-theme_yellow">State</span> and{" "}
          <span className="text-theme_yellow">Submit</span> Your Request */}
          {t('stateHeading')}
        </h1>
        <Carousel
          opts={{ align: "center" }}
          className="carousel-responsive section-margin"
        >
          <CarouselContent className="mx-auto">
            {states.map((item, index) => (
              <CarouselItem
                key={index}
                className=" md:px-2  basis-2/3 md:basis-1/3 lg:basis-1/4 2xl:basis-1/5"
              >
                <div
                  className={cn(styles.stateComponents)}
                  style={
                    {
                      "--state-bg-color": item.bgColor,
                      "--state-bg-image": `url(${item.bgImage})`,
                    } as React.CSSProperties
                  }
                >
                  <h2>{t(item.name)}</h2>
                  <p className="max-w-[20ch] font-light line-clamp-3">
                    {t(item.description)}
                  </p>
                  <div>
                    <a href={item.link} target="_blank">
                      <img
                        src={linkIcon}
                        width={32}
                        height={32}
                        alt={item.name}
                      />
                    </a>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </section>
  );
}
