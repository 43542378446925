import axios from "axios";
import sha256 from 'crypto-js/sha256';
// axios.defaults.withCredentials = true

const AxiosUtils = axios.create({
    // withCredentials:true
    baseURL: `${process.env.REACT_APP_API_URL}`
    // baseURL: 'http://localhost:8080'
    // baseURL: 'http://lm-demo.centralindia.cloudapp.azure.com'
})

async function GenerateOneSecondToken(timeOffset = 10,body?:any) {
    // Get the current UTC time in seconds since epoch with the given time offset
    const currentTime = Math.floor((Date.now() / 1000) + timeOffset);

    // Create a unique string using the current time and secret key
    let uniqueString = `${currentTime}xC0s0+sB1aQR0R21Ob9bSg==`;
    if(body){
        uniqueString = `${uniqueString}${typeof body === 'object' ? JSON.stringify(body) : body}`;
    }

    // const dataBuffer = new TextEncoder().encode(uniqueString);
    const hash =sha256(uniqueString)
    return hash.toString()
    // const hash = await crypto.subtle.digest('SHA-256', dataBuffer);
    // const hashArray = Array.from(new Uint8Array(hash));
    // return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')

}

AxiosUtils.interceptors.request.use( async (request) => {
    let hash = await GenerateOneSecondToken(0)
    if(request.data){
        hash = await GenerateOneSecondToken(0,request.data)
    }
    request.headers["x-ham-key"] =  hash;
    return request
},(error)=>{
    return Promise.reject(error);
})



export default AxiosUtils