import AxiosUtils from "../../Utils/axios.utils";
import Swal from "sweetalert2";

export type TLicence = {
  blob(): unknown;
  id: string;
  // companyName: string,
  // certificateNumber: string,
  // certificateLink:string
  link: string;
};

export const GetLicenceData = async (
  licenceNumber: string,
  certificateType: string
): Promise<TLicence | undefined> => {
  try {
    const response = await AxiosUtils.post("/api/licence", {
      licence_number: licenceNumber,
      certificate_type: certificateType,
    });
    if (response.status != 200) {
      throw new Error("error while fetch");
    }
    return response.data.data as TLicence;
  } catch (e: any) {
    if (e.status && e.status == 404) {
      //   alert("Licence Number Not Found In Our Records");
      Swal.fire({
        title: "Licence Number Not Found In Our Records",
        icon: "warning",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
    // console.log(e);
    return undefined;
  }
};
